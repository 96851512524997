.rootDisable
{
    pointer-events: none;
    opacity: 0.4;
}
.popover-basic{
    background: #fff;
    margin: 20; 
    color: #fff; 
    display: block
}
.padding-15{
    padding: 15px;
}
.popover-heading{
    margin: 30px 0 !important;
}
.popover-content{
    font-size: 14px;
    color: #838383 !important;
}
.arrowbtns{
    border-radius: 50% !important;
    min-width: inherit !important;
    margin-right: 15px !important;
    margin-bottom:28px !important;
}
.MuiMobileStepper-dots{
    display: block !important;
    width: 100%;
    text-align: center;
}
.MuiMobileStepper-dot{
    display: inline-block !important;
    border: 2px solid #E9E9E9 !important;
    width: 10px !important;
    height: 10px !important;
    background-color: transparent !important;
    margin: 9px!important;
    cursor: pointer;
    margin-bottom:19px !important;
}
.MuiMobileStepper-dot.MuiMobileStepper-dotActive{
    background-color: #F2B10E !important;
    border-color: #F2B10E !important;
}

.userbox1{
    display: block;
}
.userbox1 div{
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    cursor:'pointer'
}
.userbox1 button{
    border: 0.5px solid #E7E7E7;
    border-radius: 50%;
    padding: 0;
    padding-left: 0px;
    text-align: center;
    width: 30px !important;
    height: 30px;
    font-size: 10px;
    justify-content: flex-end;
    align-items: center !important;
    justify-content: center !important;
    float: right;
    font-weight: normal;
    margin-top: 4px;
    position: absolute;
    right: 15px;
    margin-top: -3px;
}

