.logo{
    padding-top: 80px;
    }
    .logo svg{
        width: 110px;
        height: auto;
    }
    .filed-design div{
        padding: 0;
        opacity: 1;
    }
    .filed-design input{
        border: 1px solid #E9E9E9;
        border-radius: 1px;
        padding: 16px 25px;
        font-size: 16px;
        font-family: 'MaisonNeue-Book';
        color: #838383;
    }
    .filed-design input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: #838383;
        opacity: 1;
      }
      .filed-design input::-moz-placeholder { /* Firefox 19+ */
        color: #838383;
        opacity: 1;
      }
      .filed-design input:-ms-input-placeholder { /* IE 10+ */
        color: #838383;
        opacity: 1;
      }
      .filed-design input:-moz-placeholder { /* Firefox 18- */
        color: #838383;
        opacity: 1;
      }
      .login-links a:last-child{
          color: #838383;
      }
      .error-form{
          color:'#ff0000';
      }

 @media only screen and (max-width: 767px){

    .logo{
        padding: 0;
    }
    .filed-design{
        display: block !important;
    }
    .filed-design div{
        margin-bottom: 10px;
    }
    .filed-design input{
        padding: 15px;
        font-size: 15px;
    }
    .button-design{
        float: right;
    }
    .login-links{
        display: inline-flex !important;
        margin-top: -60px;
    }

 }