@font-face {
    font-family: 'FoundersGrotesk-Medium';
    src: local('FoundersGrotesk-Medium'), url(FoundersGrotesk-Medium.woff2) format('woff2');
}
@font-face {
    font-family: 'FoundersGrotesk-MediumItalic';
    src: local('FoundersGrotesk-MediumItalic'), url(FoundersGrotesk-MediumItalic.woff2) format('woff2');
}
@font-face {
    font-family: 'FoundersGrotesk-Regular';
    src: local('FoundersGrotesk-Regular'), url(FoundersGrotesk-Regular.woff2) format('woff2');
}
@font-face {
    font-family: 'FoundersGrotesk-RegularItalic';
    src: local('FoundersGrotesk-RegularItalic'), url(FoundersGrotesk-Regular.woff2) format('woff2');
}
@font-face {
    font-family: 'MaisonNeue-Bold';
    src: local('MaisonNeue-Bold'), url(MaisonNeue-Bold.woff2) format('woff2');
}
@font-face {
    font-family: 'MaisonNeue-BoldItalic';
    src: local('MaisonNeue-BoldItalic'), url(MaisonNeue-BoldItalic.woff2) format('woff2');
}
@font-face {
    font-family: 'MaisonNeue-Book';
    src: local('MaisonNeue-Book'), url(MaisonNeue-Book.woff2) format('woff2');
}
@font-face {
    font-family: 'MaisonNeue-BookItalic';
    src: local('MaisonNeue-BookItalic'), url(MaisonNeue-BookItalic.woff2) format('woff2');
}
@font-face {
    font-family: 'MaisonNeue-Medium';
    src: local('MaisonNeue-Medium'), url(MaisonNeue-Medium.woff2) format('woff2');
}
@font-face {
    font-family: 'MaisonNeue-MediumItalic';
    src: local('MaisonNeue-MediumItalic'), url(MaisonNeue-MediumItalic.woff2) format('woff2');
}
